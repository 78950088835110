var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"answerWrap"},[_c('div',{staticClass:"answerBtn",on:{"click":_vm.upOrDown}},[_vm._m(0),_vm._v(" 答题卡 "),(!_vm.answerShow)?_c('i',{staticClass:"el-icon-arrow-up rightIcon"}):_c('i',{staticClass:"el-icon-arrow-down rightIcon"})]),(_vm.answerShow)?_c('div',{staticClass:"answerList"},[(_vm.type)?_c('div',{staticClass:"ansQue"},_vm._l((_vm.answerTotal),function(ite,inde){return _c('div',{key:inde},[_c('h2',{staticClass:"fontSize16"},[_vm._v(_vm._s(ite.title))]),_vm._l((ite.timu),function(item,index){return _c('div',{key:index,staticStyle:{"display":"inline"}},[(!item.list)?[_c('em',{class:{
                checkColor:
                  ((item.rtype == 1 || item.rtype == 5) && item.radio) ||
                  ((item.rtype == 2 || item.rtype == 3) &&
                    item.radio.length > 0) ||
                  (item.rtype == 4 && item.radio),
              },on:{"click":function($event){return _vm.jump(item.question_seq)}}},[_vm._v(" "+_vm._s(item.question_seq)+" ")]),((index + 1) % 5 == 0 && (index + 1) % 15 != 0)?_c('i'):_vm._e()]:_vm._e(),(item.list)?_vm._l((item.list),function(item1,index1){return _c('strong',{key:index1,staticStyle:{"font-weight":"normal"}},[_c('em',{class:{
                  checkColor:
                    ((item1.rtype == 1 || item1.rtype == 5) && item1.radio) ||
                    ((item1.rtype == 2 || item1.rtype == 3) &&
                      item1.radio.length > 0) ||
                    (item1.rtype == 4 && item1.radio),
                },on:{"click":function($event){return _vm.jump(item1.question_seq)}}},[_vm._v(" "+_vm._s(item1.question_seq)+" ")]),((index1 + 1) % 5 == 0 && (index1 + 1) % 15 != 0)?_c('i'):_vm._e()])}):_vm._e()],2)})],2)}),0):_c('div',{staticClass:"ansQue"},_vm._l((_vm.answerTotal),function(item,index){return _c('span',{key:index},[(!item.list)?[_c('em',{class:{
              checkColor:
                ((item.rtype == 1 || item.rtype == 5) && item.radio) ||
                ((item.rtype == 2 || item.rtype == 3) &&
                  item.radio.length > 0) ||
                (item.rtype == 4 && item.radio),
            },on:{"click":function($event){return _vm.jump(item.question_seq)}}},[_vm._v(" "+_vm._s(item.question_seq)+" ")]),((index + 1) % 5 == 0 && (index + 1) % 15 != 0)?_c('i'):_vm._e()]:_vm._e(),(item.list)?_vm._l((item.list),function(item1,index1){return _c('strong',{key:index1,staticStyle:{"font-weight":"normal"}},[_c('em',{class:{
                checkColor:
                  ((item1.rtype == 1 || item1.rtype == 5) && item1.radio) ||
                  ((item1.rtype == 2 || item1.rtype == 3) &&
                    item1.radio.length > 0) ||
                  (item1.rtype == 4 && item1.radio),
              },on:{"click":function($event){return _vm.jump(item1.question_seq)}}},[_vm._v(" "+_vm._s(item1.question_seq)+" ")]),((index1 + 1) % 5 == 0 && (index1 + 1) % 15 != 0)?_c('i'):_vm._e()])}):_vm._e()],2)}),0)]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('i',{staticClass:"leftIcon"},[_c('img',{attrs:{"src":require("../../assets/images/icon_dp.png"),"alt":""}})])}]

export { render, staticRenderFns }