import { render, staticRenderFns } from "./AnswerSheet.vue?vue&type=template&id=00370b4e&scoped=true&"
import script from "./AnswerSheet.vue?vue&type=script&lang=js&"
export * from "./AnswerSheet.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00370b4e",
  null
  
)

export default component.exports