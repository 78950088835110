<template>
  <!-- 多选 -->
  <div class="checkWrap" :id="'question' + list.question_seq">
    <b class="itemNum">{{ list.question_seq }}</b>
    <div class="itemDetail">
      <div class="detailTitle">
        <div class="mb10">
          <span class="detailMate" v-if="is_stuff == 1">资料</span>
          <span class="detailCate">{{
            list.rtype == 2 ? "多选题" : "不定项选择题"
          }}</span>
          <span class="detailScore" v-if="list.score"
            >[{{ list.score }}分]</span
          >
        </div>
        <div class="detailContent" v-html="list.title"></div>
      </div>
      <div class="detailOption">
        <el-checkbox-group v-model="list.radio" :disabled="list.subFlag">
          <el-checkbox
            :label="item.option_mark"
            v-for="(item, index) in list.options"
            :key="index"
            :class="item.className"
          >
            <i class="optionIcon"></i>{{ item.option_mark }}
            <div class="optionContent" v-html="item.option_content"></div>
          </el-checkbox>
        </el-checkbox-group>
        <span class="queding" @click="sub()" v-if="!list.subFlag">确定</span>
      </div>
      <Point :thought_dial="list" v-if="list.subFlag"></Point>
    </div>
    <i class="el-icon-delete" v-if="$route.query.num" @click="del"></i>
    <i
      class="el-icon-star-on itemGreyStar"
      v-if="list.iscollect == 0"
      @click="collect(0)"
    ></i>
    <i
      class="el-icon-star-on itemYellowStar"
      v-if="list.iscollect == 1"
      @click="collect(1)"
    ></i>
  </div>
</template>

<script>
import { updatecollect, delcollect, delwrong } from "../assets/api";
import Point from "./com/Point.vue";
export default {
  props: {
    list: Object,
    is_stuff: Number,
  },
  components: {
    Point,
  },
  data() {
    return {
      area_type1: "",
    };
  },
  mounted() {
    if (
      this.$route.query.area_type != undefined &&
      this.$route.query.area_type != ""
    ) {
      this.area_type1 = this.$route.query.area_type;
    }
  },
  methods: {
    del() {
      const params = {
        wrong_id: this.$route.query.wrongid,
      };
      delwrong(params).then((res) => {
        if (res.code == 0) {
          this.$message.success("删除成功");
          this.$router.go(-1);
        }
      });
    },
    sub() {
      this.$set(this.list, "subFlag", true);
      this.list.result = this.list.result.split(",");
      if (this.list.radio.length > 0) {
        this.list.radio.sort();
        let check = JSON.parse(JSON.stringify(this.list.radio.sort()));
        if (this.list.result.toString() == check.toString()) {
          this.flag = "回答正确";
          this.list.radio.map((item) => {
            this.list.options.map((ite) => {
              if (item == ite.option_mark) {
                this.$set(ite, "className", "active");
              }
            });
          });
        } else {
          this.flag = "回答错误";
          this.list.options.map((ite) => {
            this.list.radio.map(() => {
              if (
                ite.is_correct == 1 &&
                this.list.radio.indexOf(ite.option_mark) != -1
              ) {
                this.$set(ite, "className", "active");
              } else if (
                ite.is_correct == 1 &&
                this.list.radio.indexOf(ite.option_mark) == -1
              ) {
                this.$set(ite, "className", "right");
              } else if (this.list.radio.indexOf(ite.option_mark) != -1) {
                this.$set(ite, "className", "error");
              } else {
                this.$set(ite, "className", "");
              }
            });
          });
        }
      } else {
        this.list.options.map((ite) => {
          this.list.result.map((item) => {
            if (item == ite.option_mark) {
              this.$set(ite, "className", "right");
            }
          });
        });
        this.flag = "回答错误";
      }
    },
    collect(type) {
      if (type == 0) {
        let params = {
          column_id: sessionStorage.getItem("column_id"),
          collecttype: 1,
          question_paper_id: this.list.question_id,
        };
        updatecollect(params).then((res) => {
          if (res.code == 0) {
            this.list.collect_id = res.data.collect_id;
            this.list.iscollect = 1;
            this.$message({
              message: "收藏成功",
              type: "success",
            });
          }
        });
      } else {
        let params = {
          column_id: sessionStorage.getItem("column_id"),
          collecttype: 1,
          collect_id: this.list.collect_id,
        };
        delcollect(params).then((res) => {
          if (res.code == 0) {
            this.list.collect_id = 0;
            this.list.iscollect = 0;
            this.$message({
              message: "取消收藏成功",
              type: "success",
            });
          }
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.el-icon-delete {
  color: #bbb;
  margin-top: 9px;
  font-size: 16px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
</style>
