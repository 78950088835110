<template>
  <!-- 论述 -->
  <div class="discussWrap" :id="'question' + list.question_seq">
    <b class="itemNum">{{ list.question_seq }}</b>
    <div class="itemDetail">
      <div class="detailTitle">
        <div class="mb10">
          <span class="detailMate" v-if="is_stuff == 1">资料</span>
          <span class="detailCate">论述题</span>
          <span class="detailScore" v-if="list.score"
            >[{{ list.score }}分]</span
          >
        </div>
        <div class="detailContent" v-html="list.title"></div>
      </div>
      <div class="detailOption">
        <div class="optionWrap" v-if="!list.subFlag">
          <el-input
            type="textarea"
            :rows="8"
            placeholder="请输入内容"
            v-model="list.radio"
            class="mb20"
          ></el-input>
          <!--          <el-upload-->
          <!--            :data="upld"-->
          <!--            action="https://jsonplaceholder.typicode.com/posts/"-->
          <!--            list-type="picture-card"-->
          <!--            :limit="9"-->
          <!--            :file-list="list.stu_img"-->
          <!--            :on-preview="handlePictureCardPreview"-->
          <!--            :on-remove="handleRemove"-->
          <!--            :on-success="sucesspic"-->
          <!--          >-->
          <!--            <i class="el-icon-plus"></i>-->
          <!--            <br />-->
          <!--            <span>添加图片</span>-->
          <!--          </el-upload>-->
          <!--          <el-dialog :visible.sync="dialogVisible">-->
          <!--            <img width="100%" :src="dialogImageUrl" alt="" />-->
          <!--          </el-dialog>-->
        </div>
        <span class="queding" @click="sub()" v-if="!list.subFlag">确定</span>
      </div>
      <Point :thought_dial="list" v-if="list.subFlag"></Point>
    </div>
    <i class="el-icon-delete" v-if="$route.query.num" @click="del"></i>
    <i
      class="el-icon-star-on itemGreyStar"
      v-if="list.iscollect == 0"
      @click="collect(0)"
    ></i>
    <i
      class="el-icon-star-on itemYellowStar"
      v-if="list.iscollect == 1"
      @click="collect(1)"
    ></i>
  </div>
</template>

<script>
import { updatecollect, delcollect, delwrong } from "../assets/api";
import Point from "./com/Point.vue";
export default {
  props: {
    list: Object,
    is_stuff: Number,
  },
  components: {
    Point,
  },
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,
      upld: {
        exam_id: this.$store.state.exam_id,
        area_id: this.$store.state.area_id,
      },
      area_type1: "",
    };
  },
  mounted() {
    if (
      this.$route.query.area_type != undefined &&
      this.$route.query.area_type != ""
    ) {
      this.area_type1 = this.$route.query.area_type;
    }
  },
  methods: {
    del() {
      const params = {
        wrong_id: this.$route.query.wrongid,
      };
      delwrong(params).then((res) => {
        if (res.code == 0) {
          this.$message.success("删除成功");
          this.$router.go(-1);
        }
      });
    },
    collectTi() {
      let params = {
        column_id: sessionStorage.getItem("column_id"),
        collecttype: 1,
        collect_id: this.$route.query.collectid,
      };
      delcollect(params).then((res) => {
        if (res.code == 0) {
          this.$message.success("取消收藏成功");
          this.$router.go(-1);
        }
      });
    },
    collect(type) {
      if (type == 0) {
        let params = {
          column_id: sessionStorage.getItem("column_id"),
          collecttype: 1,
          question_paper_id: this.list.question_id,
        };
        updatecollect(params).then((res) => {
          if (res.code == 0) {
            this.list.collect_id = res.data.collect_id;
            this.list.iscollect = 1;
            this.$message({
              message: "收藏成功",
              type: "success",
            });
          }
        });
      } else {
        let params = {
          column_id: sessionStorage.getItem("column_id"),
          collecttype: 1,
          collect_id: this.list.collect_id,
        };
        delcollect(params).then((res) => {
          if (res.code == 0) {
            this.list.collect_id = 0;
            this.list.iscollect = 0;
            this.$message({
              message: "取消收藏成功",
              type: "success",
            });
          }
        });
      }
    },
    //上传图片
    sucesspic(file) {
      this.$set(this.list, "stu_img", []);
      this.list.stu_img.push({ url: file.ossurl });
    },
    handleRemove(file, fileList) {
      this.list.stu_img.forEach((item, index) => {
        if (file.url == item.url) {
          this.list.stu_img.splice(index, 1);
        }
      });
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    sub() {
      this.$set(this.list, "subFlag", true);
    },
  },
};
</script>

<style scoped lang=""></style>
