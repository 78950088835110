<template>
  <div class="detailThought">
    <em class="jiantou"
      ><img src="../../assets/images/uxb_cbg1.png" alt=""
    /></em>
    <div class="detailThoughtdiv1" v-if="thought_dial.rtype == 4">
      <h6><i class="iconfont icon-lunsu"></i>你的答案</h6>
      <div v-if="thought_dial.radio==''" style="color: #999">未作答</div>
      <div v-else v-html="thought_dial.radio"></div>
<!--      <ul class="detailThoughtdiv1oul clearfix">-->
<!--         <li v-for="(index) in 9" :key="index">-->
<!--           <img src="../../assets/images/zg_cbg21.jpg" alt=""/>-->
<!--         </li>-->
<!--      </ul>-->
    </div>
    <div class="detailThoughtdiv" v-else-if="thought_dial.rtype == 5">
      回答{{thought_dial.result.toString() == thought_dial.radio.toString() ? "正确" : "错误"}}
    </div>
    <div class="detailThoughtdiv" v-else>
      正确答案是 <span class="zq">{{ thought_dial.result }}</span> ，你的答案是
      <span class="err">{{ thought_dial.radio }}</span> 。回答{{
        thought_dial.result.toString() == thought_dial.radio.toString() ? "正确" : "错误"
      }}
    </div>
    <div class="jiexi">
      <i class="iconfont icon-jiexi"></i
      >{{ thought_dial.rtype == 4 ? "参考答案" : "解析" }}
    </div>
    <div class="thoughtDetail" v-html="thought_dial.analysis"></div>
  </div>
</template>

<script>
export default {
  props: {
    thought_dial: Object,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang=""></style>
