<template>
  <!-- 真题作战作答 -->
  <div class="questionsWrap">
    <header>
      <div class="headerWrap">
        <div class="headerTitle">
          <!-- @click="save" -->
          <i class="el-icon-arrow-left" @click="save"></i>
          <!-- {{ question_id == "" ? Parper.title : "" }} -->
          {{ $route.query.title ? $route.query.title : Parper.title }}
        </div>
        <div class="errorWrap" v-if="$route.query.num">
          累计错误次数：<span class="errorNum">{{ $route.query.num }}</span>
        </div>
      </div>
    </header>
    <main>
      <div class="mainWrap" id="mainWrap">
        <div v-for="(item, index) in question_infos" :key="index">
          <!-- 单选 -->
          <Radio
            class="itemWrap"
            v-if="item.is_stuff == 0 && item.rtype == 1"
            :list="item"
            :is_stuff="item.is_stuff"
          ></Radio>
          <!-- 多选 -->
          <Checkbox
            class="itemWrap"
            v-if="
              (item.is_stuff == 0 && item.rtype == 2) ||
              (item.is_stuff == 0 && item.rtype == 3)
            "
            :list="item"
            :is_stuff="item.is_stuff"
          ></Checkbox>
          <!-- 判断 -->
          <Estimate
            class="itemWrap"
            v-if="item.is_stuff == 0 && item.rtype == 5"
            :list="item"
            :is_stuff="item.is_stuff"
          ></Estimate>
          <!-- 论述 -->
          <Discuss
            class="itemWrap"
            v-if="item.is_stuff == 0 && item.rtype == 4"
            :list="item"
            :is_stuff="item.is_stuff"
          ></Discuss>
          <!-- 资料 -->
          <Materials
            class="itemWrap"
            v-if="item.is_stuff == undefined"
            :list="item"
          ></Materials>
        </div>
      </div>
    </main>
    <footer v-if="$route.query.title != '我的错题'&& $route.query.title != '试题收藏'&& $route.query.title != '试题搜索'">
      <AnswerSheet :answerTotal="this.question_infos"></AnswerSheet>
    </footer>
    <aside v-if="bttype != 1 && $route.query.title != '我的错题'&& $route.query.title != '试题收藏'&& $route.query.title != '试题搜索'">
      <ul>
        <li v-if="Parper.time_limit > 0">
          <i class="iconfont icon-icon_time"></i>
          {{ downTime }}
        </li>
        <li v-else>
          <i class="iconfont icon-icon_time"></i>
          {{ upTime }}
        </li>
        <!-- <li v-if="iscollect == 0" @click="collectPaper(0)">
                    <i class="el-icon-star-off"></i>
                    收藏试卷
                </li>
                <li v-else @click="collectPaper(1)">
                    <i class="el-icon-star-on"></i>
                    取消收藏
                </li> -->
        <li @click="save">
          <i class="iconfont icon-icon_next"></i>
          下次做
        </li>
        <li @click="submit">
          <i class="iconfont icon-icon_jj"></i>
          交卷
        </li>
      </ul>
    </aside>
    <DiaLogs ref="DiaLogs"></DiaLogs>
  </div>
</template>

<script>
import { sample, question } from "./assets/api";
import Radio from "./componentsRecite/Radio"; // 单选
import Checkbox from "./componentsRecite/Checkbox"; // 多选
import Estimate from "./componentsRecite/Estimate"; // 判断
import Discuss from "./componentsRecite/Discuss"; // 论述
import Materials from "./componentsRecite/Materials"; // 材料
import AnswerSheet from "./componentsRecite/com/AnswerSheet.vue"; //答题卡
import DiaLogs from "../components/DiaLog/DiaLogs.vue"; //弹窗
export default {
  name: "SearchRespond",
  components: {
    Radio,
    Checkbox,
    Estimate,
    Discuss,
    Materials,
    AnswerSheet,
    DiaLogs,
  },
  data() {
    return {
      question_infos: [],
      questionData: {},
      num: "",
      seconds: 0,
      upTime: "",
      downTime: "",
      iscollect: "",
      bfIshow: true,
      answerTotal: [],
      area_type: "",
      newtime: "",
      Question_list: [],
      extar: {},
      Parper: "",
      papermainObj: {},
      collect_id: "",
      bttype: "",
      question_id: "",
    };
  },
  mounted() {
    if (
      this.$route.query.area_type != undefined &&
      this.$route.query.area_type != ""
    ) {
      this.area_type = this.$route.query.area_type;
    }
    if (
      this.$route.query.area_type != undefined &&
      this.$route.query.area_type != ""
    ) {
      this.bttype = this.$route.query.bttype;
    }
    if (
      this.$route.query.question_id != undefined &&
      this.$route.query.question_id != ""
    ) {
      this.question_id = this.$route.query.question_id;
    }
    if (this.area_type == 1) {
      this.getList();
    } else {
      this.question();
    }
  },
  methods: {
    //数据处理
    dataProcessing(a, b, c, d) {
      a.map((item) => {
        if (item.rtype == 1 || item.rtype == 5 || item.rtype == 4) {
          this.$set(item, "radio", "");
          if (item.user_answer != "") {
            this.$set(item, "radio", item.user_answer);
          }
        } else if (item.rtype == 2 || item.rtype == 3) {
          this.$set(item, "radio", []);
          if (item.user_answer != "") {
            var ss = item.user_answer.split(",");
            this.$set(item, "radio", ss);
          }
        }
        this.Question_list.push(item);
        this.$nextTick(() => {
          if (item.is_stuff > 0) {
            c.map((item1) => {
              if (item1.stuff_id == item.stuff_id) {
                if (!item1.list) {
                  this.$set(item1, "list", []);
                }
                this.$set(item1, "section_id", item.section_id);
                item1.list.push(item);
                b.push(item1);
                if (b.length > 0) {
                  b = this.unique(b);
                }
              }
            });
          } else {
            b.push(item);
          }
        });
        this.$nextTick(() => {
          if (d != undefined && d.length > 0) {
            d.map((itembf) => {
              this.$set(itembf, "timu", []);
              b.map((item) => {
                if (itembf.section_id == item.section_id) {
                  itembf.timu.push(item);
                }
              });
            });
            this.question_infos = d;
            this.bfIshow = true;
          } else {
            this.question_infos = b;
            this.bfIshow = false;
          }
        });
      });
    },
    unique(arr) {
      for (var i = 0; i < arr.length; i++) {
        for (var j = i + 1; j < arr.length; j++) {
          if (arr[i].is_stuff == undefined) {
            if (arr[i].stuff_id == arr[j].stuff_id) {
              arr.splice(j, 1);
              j--;
            }
          }
        }
      }
      return arr;
    },
    //获取试题-考点组题
    getList() {
      let params = {
        special_id: this.$route.query.special_id,
        column_id: sessionStorage.getItem("column_id"),
        catalogue_id: this.$route.query.catalogue_id,
        num: sessionStorage.getItem("num"),
        smodel: sessionStorage.getItem("smodel"),
        answer_id: this.$route.query.answer_id,
      };
      sample(params).then((res) => {
        if (res.code == 0) {
          if (res.data.Question_list.length > 0) {
            let b = [];
            this.newtime = res.data.Newtime;
            this.extar = res.data.Extar;
            this.Parper = res.data.Paper;
            this.Question_list = res.data.Question_list;
            this.dataProcessing(
              res.data.Question_list,
              b,
              res.data.Stuffinfos,
              res.data.Sectioninfos
            );
            setTimeout(function () {
              if (res.data.Answer_seq != "") {
                let t = document.querySelector(
                  "#question" + res.data.Answer_seq
                ).offsetTop;
                window.scrollTo(0, t - 88);
              }
            }, 1000);
            if (this.Parper.time_limit > 0) {
              this.downSec = res.data.Durationtime;
              this.timer = setInterval(this.endTimer, 1000);
            } else {
              this.timer2 = setInterval(this.startTimer, 1000);
            }
          } else {
            this.$message.error("暂无试题");
          }
        }
      });
    },
    //获取试题-查询单题
    question() {
      let params = {
        question_id: this.question_id,
        column_id: sessionStorage.getItem("column_id"),
      };
      question(params).then((res) => {
        if (res.code == 0) {
          if (res.data.Question_list.length > 0) {
            let b = [];
            this.Question_list = res.data.Question_list;
            this.dataProcessing(
              res.data.Question_list,
              b,
              res.data.Stuffinfos,
              res.data.Sectioninfos
            );
          } else {
            this.$message.error("暂无试题");
          }
        }
      });
    },
    //计时器
    startTimer() {
      this.seconds += 1;
      let m =
        Math.floor((this.seconds / 60) % 60) < 10
          ? "0" + Math.floor((this.seconds / 60) % 60)
          : Math.floor((this.seconds / 60) % 60);
      let s =
        this.seconds % 60 < 10
          ? "0" + Math.floor(this.seconds % 60)
          : Math.floor(this.seconds % 60);
      this.upTime = m + ":" + s;
    },
    endTimer() {
      this.downSec -= 1;
      let m =
        Math.floor((this.downSec / 60) % 60) < 10
          ? "0" + Math.floor((this.downSec / 60) % 60)
          : Math.floor((this.downSec / 60) % 60);
      let s =
        this.downSec % 60 < 10
          ? "0" + Math.floor(this.downSec % 60)
          : Math.floor(this.downSec % 60);
      this.downTime = m + ":" + s;
      if (this.downSec == 3) {
        clearTimeout(this.timer);
        clearTimeout(this.timer2);
        //type, diaData, answerType, tabindex, durationTime
        let b = this.Question_list;
        let ind;
        b.findIndex((item, index) => {
          if (
            (item.rtype == 1 || item.rtype == 5 || item.rtype == 4) &&
            item.radio
          ) {
            ind = index;
          }
          if ((item.rtype == 2 || item.rtype == 3) && item.radio.length > 0) {
            ind = index;
          }
        });
        let tabindex;
        if (ind) {
          tabindex = b[ind].question_seq;
        } else {
          tabindex = this.Question_list[0].question_seq;
        }
        this.$refs.DiaLogs.openInit(
          5,
          this.Question_list,
          this.newtime,
          this.extar,
          tabindex
        ); //考试时间到
      }
    },
    //下次做
    save() {
      if(this.$route.query.title != '我的错题'&& this.$route.query.title != '试题收藏'&& this.$route.query.title != '试题搜索'){

     
      let a = this.Question_list;
      let ind;
      a.findIndex((item, index) => {
        if (
          (item.rtype == 1 || item.rtype == 5 || item.rtype == 4) &&
          item.radio
        ) {
          ind = index;
        }
        if ((item.rtype == 2 || item.rtype == 3) && item.radio.length > 0) {
          ind = index;
        }
      });
      let tabindex;
      if (ind) {
        tabindex = a[ind].question_seq;
      } else {
        tabindex = this.Question_list[0].question_seq;
      }
      //type, diaData, answerType, tabindex, durationTime
      this.$refs.DiaLogs.openInit(
        4,
        this.Question_list,
        this.newtime,
        this.extar,
        tabindex
      );
    }else{
      this.$router.go(-1)
    }
    },
    //交卷
    submit() {
      let a = this.question_infos.every((item) => {
        return (
          ((item.rtype == 1 || item.rtype == 5 || item.rtype == 4) &&
            item.radio) ||
          ((item.rtype == 2 || item.rtype == 3) && item.radio.length > 0)
        );
      });
      let b = this.Question_list;
      let ind;
      b.findIndex((item, index) => {
        if (
          (item.rtype == 1 || item.rtype == 5 || item.rtype == 4) &&
          item.radio
        ) {
          ind = index;
        }
        if ((item.rtype == 2 || item.rtype == 3) && item.radio.length > 0) {
          ind = index;
        }
      });
      let tabindex;
      if (ind) {
        tabindex = b[ind].question_seq;
      } else {
        tabindex = this.Question_list[0].question_seq;
      }

      if (a) {
        this.$refs.DiaLogs.openInit(
          6,
          this.Question_list,
          this.newtime,
          this.extar,
          tabindex
        );
      } else {
        this.$refs.DiaLogs.openInit(
          1,
          this.Question_list,
          this.newtime,
          this.extar,
          tabindex
        );
      }
    },
  },
  destroyed() {
    if (
      this.questionData.time_duration != 0 ||
      this.questionData.time_duration != ""
    ) {
      clearTimeout(this.timer);
    }
    clearTimeout(this.timer2);
  },
};
</script>

<style scoped lang="scss">
.errorWrap {
  font-size: 16px;
}
.errorNum {
  color: red;
  font-weight: bold;
}
</style>
