<template>
  <!-- 判断 -->
  <div class="estimateWrap" :id="'question' + list.question_seq">
    <b class="itemNum">{{ list.question_seq }}</b>
    <div class="itemDetail">
      <div class="detailTitle">
        <div class="mb10">
          <span class="detailMate" v-if="is_stuff == 1">资料</span>
          <span class="detailCate">判断题</span>
          <span class="detailScore" v-if="list.score"
            >[{{ list.score }}分]</span
          >
        </div>
        <div class="detailContent" v-html="list.title"></div>
      </div>
      <div class="detailOption">
        <el-radio-group v-model="list.radio">
          <el-radio
            :label="item.option_mark"
            v-for="(item, index) in list.options"
            :key="index"
            @change="radioOptions()"
            :disabled="list.radio != ''"
            :class="item.className"
          >
            <!-- <i class="optionIcon"><i class="el-icon-check"></i></i> -->
            <i class="optionIcon"></i>
            <div class="optionContent" v-html="item.option_content"></div>
          </el-radio>
        </el-radio-group>
      </div>
      <Point :thought_dial="list" v-if="list.radio != ''"></Point>
    </div>
    <i class="el-icon-delete" v-if="$route.query.num" @click="del"></i>
    <i
      class="el-icon-star-on itemGreyStar"
      v-if="list.iscollect == 0"
      @click="collect(0)"
    ></i>
    <i
      class="el-icon-star-on itemYellowStar"
      v-if="list.iscollect == 1"
      @click="collect(1)"
    ></i>
  </div>
</template>

<script>
import { updatecollect, delcollect, delwrong } from "../assets/api";
import Point from "./com/Point.vue";
export default {
  props: {
    list: Object,
    is_stuff: Number,
  },
  components: {
    Point,
  },
  data() {
    return {
      radio: "",
      area_type1: "",
    };
  },
  mounted() {
    if (
      this.$route.query.area_type != undefined &&
      this.$route.query.area_type != ""
    ) {
      this.area_type1 = this.$route.query.area_type;
    }
  },
  methods: {
    del() {
      const params = {
        wrong_id: this.$route.query.wrongid,
      };
      delwrong(params).then((res) => {
        if (res.code == 0) {
          this.$message.success("删除成功");
          this.$router.go(-1);
        }
      });
    },
    radioOptions() {
      if (this.list.radio != "") {
        this.list.options.forEach((item) => {
          if (
            item.option_mark != this.list.result &&
            this.list.radio == item.option_mark
          ) {
            this.$set(item, "className", "error");
            this.flag = "回答错误";
          } else if (
            item.option_mark == this.list.result &&
            this.list.radio == item.option_mark
          ) {
            this.$set(item, "className", "active");
            this.flag = "回答正确";
          } else if (
            item.option_mark == this.list.result &&
            this.list.radio != item.option_mark
          ) {
            this.$set(item, "className", "right");
            this.flag = "回答错误";
          }
        });
      }
    },
    collectTi() {
      let params = {
        column_id: sessionStorage.getItem("column_id"),
        collecttype: 1,
        collect_id: this.$route.query.collectid,
      };
      delcollect(params).then((res) => {
        if (res.code == 0) {
          this.$message.success("取消收藏成功");
          this.$router.go(-1);
        }
      });
    },
    collect(type) {
      if (type == 0) {
        let params = {
          column_id: sessionStorage.getItem("column_id"),
          collecttype: 1,
          question_paper_id: this.list.question_id,
        };
        updatecollect(params).then((res) => {
          if (res.code == 0) {
            this.list.collect_id = res.data.collect_id;
            this.list.iscollect = 1;
            this.$message({
              message: "收藏成功",
              type: "success",
            });
          }
        });
      } else {
        let params = {
          column_id: sessionStorage.getItem("column_id"),
          collecttype: 1,
          collect_id: this.list.collect_id,
        };
        delcollect(params).then((res) => {
          if (res.code == 0) {
            this.list.collect_id = 0;
            this.list.iscollect = 0;
            this.$message({
              message: "取消收藏成功",
              type: "success",
            });
          }
        });
      }
    },
  },
};
</script>


<style scoped lang="scss">
.el-icon-delete {
  color: #bbb;
  margin-top: 9px;
  font-size: 16px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}
</style>
